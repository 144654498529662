<template>
<div>
<div>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                v-model="filterLeague"
                :items="leagues"
                label="Search by league"
                outlined
                dense
                item-text="name"
                item-value="id"
                @input="initialize()"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                v-model="filterSeason"
                :items="seasons"
                label="Years"
                outlined
                dense
                attach
                item-color="primary"
            
                primary
              
                item-text="name"
                item-value="id"
                @input="initialize()"
              
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
</div>

<vue-chartist :data="history" :options="options" type="Line"></vue-chartist>


<vue-chartist :data="simulation" :options="options2" type="Line"></vue-chartist>


<vue-chartist :data="nodraw" :options="options3" type="Line"></vue-chartist>

<vue-chartist :data="draw_minute" :options="options4" type="Line"></vue-chartist>


<vue-chartist :data="pandl" :options="options5" type="Line"></vue-chartist>







</div>
</template>
<style scope>

.ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: red;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
.ct-series-b .ct-line {
  /* Set the colour of this series line */
  stroke: green;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
.ct-series-c .ct-line {
  /* Set the colour of this series line */
  stroke: blue;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
</style>
<script>
import axios from "axios";
//import annotation from "chartjs-plugin-annotation"

import VueChartist from 'v-chartist';
import '../../node_modules/chartist/dist/chartist.min.css'

export default {
  components: {
    'vue-chartist': VueChartist
  },

  data: () => ({ 
    history: {
      series: [[]],
      labels: []
    },
    simulation: {
      series: [[]],
      labels: []
    },
    nodraw: {
      series: [[]],
      labels: []
    },
    draw_minute: {
      series: [[]],
      labels: []
    },
    pandl: {
      series: [[]],
      labels: []
    },
    options: {
      distributeSeries: true,
      fullWidth: true,
      height:250,
      series: {
        'avg': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        },
        'all_avg': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        },
        'whl_avg': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        }
      },
      low:0,
      tooltips: {
        mode: "index",
        intersect: true
      },
      /*aplugins: [
         annotation( {
            drawTime: 'afterDatasetsDraw', // (default)

            // Double-click speed in ms used to distinguish single-clicks from
            // double-clicks whenever you need to capture both. When listening for
            // both click and dblclick, click events will be delayed by this
            // amount.
            dblClickSpeed: 350, // ms (default)

            annotations: [
            {
              drawTime: "afterDatasetsDraw",
              id: "hline",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: 25,
              borderColor: "black",
              display: true,
              borderWidth: 5,
              label: {
                backgroundColor: "red",
                content: "Test Label",
                enabled: true
              }
            }
          /*,
          {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            xScaleID: "x-axis-0",
            yScaleID: "y-axis-0",
            xMin: "1",
            xMax: "96",
            yMin: 10,
            yMax: 40,
            backgroundColor: "rgba(101, 33, 171, 0.5)",
            borderColor: "rgb(101, 33, 171)",
            borderWidth: 1
          } 
            ]
        
        }
         )]*/
    },
    options3: {
      distributeSeries: true,
      fullWidth: true,
      height:250,
      series: {
        'no_draw_avg': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        },
        'libility': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        }
      },
      low:0,
      tooltips: {
        mode: "index",
        intersect: true
      },
    },
      options2: {
        
      distributeSeries: true,
      fullWidth: true,
      height:250,
      series: {
        'balance': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        }
      },
      low:0,
      tooltips: {
        mode: "index",
        intersect: true
      },
    },
          options4: {
      distributeSeries: true,
      fullWidth: true,
      height:250,
      series: {
        'draw_minute': {
          showPoint: false,
        // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        }
      },
      low:0,
      tooltips: {
        mode: "index",
        intersect: true
      },
    },
    options5: {
      distributeSeries: true,
      fullWidth: true,
      height:250,
      onlyInteger: false,
      series: {
        'pandl': {
          showPoint: false,
        // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        }
      },
      tooltips: {
        mode: "index",
        intersect: true
      },
    },
    filterLeague: 'italy2',
    filterSeason: '2023',
    seasons: [
            { id: '2023', name: '2022/2023'},
      { id: '2022', name: '2021/2022'},
      { id: '2021', name: '2020/2021'},
      { id: '2020', name: '2019/2020'},
      { id: '2019', name: '2018/2019'},
      { id: '2018', name: '2017/2018'},
      { id: '2017', name: '2016/2017'},
      { id: '2016', name: '2015/2016'}
    ],
    loadingOverlay: false,
    leagues: [
      { id: "italy2", name: "Italy - Serie B" },
      { id: "spain2", name: "Spain - La liga 2" },
      { id: "france2", name: "French - Ligue 2" },
      { id: "england2", name: 'English - Championships'},
      { id: "germany2", name: 'German - 2. Bundesliga'},
      { id: "brazil2", name: 'Brazil - Serie B'},
      { id: "league2", name: "Summary of second leagues"}
     // { id: "england2", name: "England - Championschips"}
    ],
  }),
  created() {
    this.initialize();
  },

  methods: {
   
    findLeague(league_id) {
      return this.leagues.find((l) => l.id == league_id).name;
    },

    initialize() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/history/"+ this.filterLeague +"/" + this.filterSeason;
      this.loadingOverlay = true;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          this.history = {
            series: [{name: 'avg', data: []},{name: 'all_avg', data: []},{name:'whl_avg', data: [] }],
            labels: [],
          }
          
          var avgPeriod = 60
//for (var f=0;f <avgPeriod;f++) {this.history.series[1].data.push(0)}
          var rotateAvg = 10
          var data = response.data
          var movingAvg = []
          var whl_avg = 0
         // var allAvg = 0  
          for(var i=0;i < data.length;i++) {
            var event = data[i]
            var resoult = Number(event.home_score) == Number(event.away_score) ? 1 : 0
            movingAvg.push(resoult)
            //allAvg += resoult
            var sum = 0
            var sum22 = 0
            whl_avg += resoult
            if (i > avgPeriod) {
              for (var a=movingAvg.length-avgPeriod; a < movingAvg.length; a++) {
                sum += movingAvg[a]
              }
              this.history.series[0].data.push((sum/avgPeriod)*100)
              this.history.labels.push(Number(i))
              //console.log()
              this.history.series[2].data.push((whl_avg/i)*100)
            }
            if (i > 22) {
              for (var m=movingAvg.length-(movingAvg.length-rotateAvg <= 0 ? movingAvg.length : rotateAvg); m < movingAvg.length; m++) 
                sum22 += movingAvg[m]
            
              this.history.series[1].data.push((sum22/rotateAvg)*100)
            }
 
              
          }
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });

      url = "https://a.bet.lukaszk.usermd.net/v1/simulation2/"+ this.filterLeague +"/" + this.filterSeason;
      this.loadingOverlay = true;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          var data = response.data
          this.simulation = {
            series: [{name: 'balance', data: []}],
            labels: [],
          }
          this.draw_minute = {
            series: [{name: 'draw_minute', data: []}],
            labels: [],
          }
          this.nodraw = {
            series: [{name: 'no_draw_avg', data: []},{name:'liblity', data: []}],
            labels: [],
          }  
          this.pandl = {
            series: [{name: 'pandl', data: []},{name:'liblity', data: []}],
            labels: [],
          }      
          var last_date = '' 
          for(var i=0;i < data.length;i++) {
            var team = data[i]

            this.nodraw.series[0].data.push(team.stats.no_draw_avg);          
            this.nodraw.series[1].data.push(team.stats.teams_libility);   
            this.draw_minute.series[0].data.push(team.last_draw_minute);  
            this.pandl.series[0].data.push(team.pandl);  
            
            if (team.match_date != last_date) {
              this.simulation.series[0].data.push(team.stats.balance);
              last_date = team.match_date   
            } 

          }
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });
    },
  },
};
</script>
