<template>
  <v-app>
    <v-app-bar app absolute color="blue-grey" dark scroll-threshold flat>
      <v-app-bar-title class="ma-5">Betting system</v-app-bar-title>
      <v-spacer></v-spacer>
      <span>You placed first bet <strong>{{ balance.days_past }}</strong> days ago. </span>
      <v-chip color="yellow text--white darken-4" class="ml-4">Daily profit: <strong> {{Number((balance.won.amount-balance.won.spent-balance.lost.amount)/balance.days_past).toFixed(2) }}&pound;</strong></v-chip>
      <v-chip color="grey text--white " class="ml-2">Exposure:  <strong> {{Number(balance.pending.amount).toFixed(2) }}&pound;</strong></v-chip>
      <v-chip color="green text--white ligthen-1" class="ml-2">Total profit: <strong> {{ Number(balance.won.amount-balance.won.spent-balance.lost.amount).toFixed(2) }}&pound;</strong></v-chip>
      <v-chip color="primary text--white lighten-1" class="ml-2">Return: <strong> {{ Number(balance.won.amount-balance.won.spent).toFixed(2) }}&pound;</strong></v-chip>
      <v-chip color="red text--white lighten-1" class="ml-2">Lost: <strong> {{ Number(balance.lost.amount).toFixed(2) }}&pound;</strong></v-chip>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab to="/">Chased</v-tab>
          <!--v-tab to="/watchlist">Watchlist</v-tab-->
          <v-tab to="/events">Events</v-tab>
          <v-tab to="/history">History</v-tab>
          <v-tab to="/matcher">Odds Matcher</v-tab>
          <v-tab to="/proposals">Proposals</v-tab>
          <v-tab to="/coupons">Coupons</v-tab>
          <v-tab to="/cashout">Cashout</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
//import axios from "axios";

export default {
  name: "App",

  data: () => ({
    balance: { 
        won: { amount: 0 }, 
        lost: { amount: 0 }, 
        pending: { amount: 0 },
        days_past: 0
    },
  }),
  created() {
    this.initialize();
  },

  methods: {

    initialize() {

      return; 
      /* 
      var url = "https://a.bet.lukaszk.usermd.net/v1/balance";
      axios({
        url: url,
        method: "get",
      })
        .then((response) => {
          this.balance.won = response.data.won[0];
          this.balance.lost = response.data.lost[0];
          this.balance.days_past = response.data.days_past;
          this.balance.pending = response.data.pending[0];
        })
        .catch((error) => {
          console.log(error);
        }).then(() => {
        //  setTimeout(function(){ initialize() }, 2000);
        })
        */
    },
  },
};
</script>
