<template>
  <div class="about">
    <!--v-layout wrap>
    <v-flex v-for="(event, i) in events" :key="i" md3 class="pa-2">
        <v-card>
            <v-card-title class="body-1">
             {{ events.find((e) => e.id === market.event_id).name }} <br/>
             
            </v-card-title>
    
            <v-card-text>
         
                             <v-chip color="grey"  text-color="white" class="ml-1">{{ getPriceByMarket(market.id, 'HOME') }}</v-chip> 
                <v-chip color="primary"  text-color="white" class="ml-1">{{ getPriceByMarket(market.id, 'DRAW') }}</v-chip> 
                <v-chip color="grey" text-color="white" class="ml-1">{{ getPriceByMarket(market.id, 'AWAY') }}</v-chip>
       <strong class="ml-2"> {{ findLeague(events.find((e) => e.id === market.event_id).parent_id) }} </strong>
                        <v-divider class="mt-2"></v-divider>
              <span class="font-italic">Data rozpoczęcia</span>    <span class="body-2"><strong> {{ events.find((e) => e.id === market.event_id).start_date }} </strong></span>
             
            
            </v-card-text>
        </v-card>
    </v-flex>
  </v-layout-->

    <v-card>
      <v-card-title>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                v-model="filterStatus"
                :items="searchByStatus"
                label="Search by status"
                outlined
                dense
                
                item-text="state"
                item-value="value"
                @input="initialize()"
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search for a team..."    
        single-line
        outlined
        hide-details
        dense
      ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="filterLeagues"
                :items="leagues"
                label="Filter leagues"
                outlined
                dense
                attach
                item-color="primary"
                        chips
        primary
                multiple
                item-text="name"
                item-value="id"
                @input="filterLeaguesChange(); initialize()"
              
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="events"
        :search="search"
        :items-per-page="50"
        item-key="event_id"
        :sort-by="['start_datetime', 'state']"
        :sort-desc="[false, true]"
        show-expand
        single-expand
        @item-expanded="loadEventInfo"
      >
    <template v-slot:expanded-item="{ headers }">
      <td :colspan="headers.length">
<vue-chartist :data="oddshistory" :options="options" type="Line"></vue-chartist>
      </td>
    </template>
        <template v-slot:item.odds_draw="{ item }">
          <v-container fluid>
            <v-row align="left">
              <v-col class="d-flex" cols="12" sm="4">

                <strong>{{ item.odds_home }}</strong>

                <span v-if="item.h_odds_home < item.odds_home"
                  >
                  <v-icon color="green" small
                    >mdi-chevron-double-up</v-icon
                  >
                  
                  {{ (item.odds_home - item.h_odds_home).toFixed(2) }}
                  </span>
                                <span v-if="item.odds_home < item.h_odds_home">
                                                  <v-icon color="red" small
                    >mdi-chevron-double-down</v-icon>
                                  
                        {{ (item.odds_home - item.h_odds_home).toFixed(2) }}
  
                  </span>

              </v-col>
              <v-col class="d-flex" cols="12" sm="4">
                <span
                  flat
                  v-if="
                    Number(item.odds_draw).toFixed(2) ==
                    Number(item.h_odds_draw).toFixed(2)
                  "
                  color="primary"
                  class="pa-2 ma-0"
                  ><strong>{{ item.odds_draw }}</strong>
                </span>
                <span
                  flat
                  v-if="item.odds_draw < item.h_odds_draw"
                  color="red ligthen-1"
                  text-color="white"
                  class="pa-1 ma-0"
                  ><strong>{{ item.odds_draw }} </strong>
                </span>
                <span v-if="item.odds_draw < item.h_odds_draw">
                  <v-icon color="red ligthen-1" small
                    >mdi-chevron-double-down</v-icon
                  >
                  {{ (item.h_odds_draw - item.odds_draw).toFixed(2) }}
                </span>
                <span
                  flat
                  v-if="item.odds_draw > item.h_odds_draw"
                  color="green lighten-1"
                  text-color="white"
                  class="pa-1 ma-0"
                  ><strong>{{ item.odds_draw }}</strong></span>
                
                <span v-if="item.odds_draw > item.h_odds_draw">
                  <v-icon color="green lighten-1" small
                    >mdi-chevron-double-up</v-icon
                  >
                  {{ (item.odds_draw - item.h_odds_draw).toFixed(2) }}
                </span>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4">
                <strong>{{ item.odds_away }}</strong>

                <span v-if="item.odds_away < item.h_odds_away"
                  ><v-icon color="red" small>mdi-chevron-double-down</v-icon>
                  {{ (item.h_odds_away - item.odds_away).toFixed(2) }}</span
                >
                <span v-if="item.h_odds_away < item.odds_away">
                  <v-icon color="green" small>mdi-chevron-double-up</v-icon>
                  {{ (item.odds_away - item.h_odds_away).toFixed(2) }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.volume="{ item }">
          <strong>{{ item.volume }}&pound;</strong>
        </template>
        <template v-slot:item.competitor_home_name="{ item }">
          <v-btn
            depressed
            @click="
              openChaseDlg(item.competitor_home_name, item.competitor_home_id)
            "
            text
            color="blue-grey darken-1"
            class="ma-0 pa-2"
            >{{ item.competitor_home_name }}</v-btn
          >
        </template>
        <template v-slot:item.competitor_away_name="{ item }">
          <v-btn
            depressed
            @click="
              openChaseDlg(item.competitor_away_name, item.competitor_away_id)
            "
            text
            color="blue-grey darken-1"
            class="ma-0 pa-2"
            >{{ item.competitor_away_name }}
          </v-btn>
        </template>

        <template v-slot:item.state="{ item }">
          {{ item.state }}
          <span v-if="item.state != 'upcoming'"
            >(<strong>{{ item.score_home }} : {{ item.score_away }}</strong
            >)</span
          >
          <v-icon
            class="ml-2"
            v-if="item.score_home == item.score_away && item.state == 'ended'"
            medium
            color="yellow darken-1"
          >
            mdi-star
          </v-icon>
        </template>
        <template v-slot:item.parent_id="{ item }">
          {{ findLeague(item.parent_id) }}
        </template>

        <template slot="no-data"> no-data </template>
        <v-alert
          slot="no-results"
          :value="true"
          color="error"
          icon="warning"
          class="pa-1"
        >
          Brak danych.
        </v-alert>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogChase.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h2>{{ dialogChase.competitor_name }}</h2>
          <span class="headline title ml-2"> Chase team draw</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" align-self="center">
                <v-text-field
                  outlined
                  label="Starting bet amount"
                  v-model="dialogChase.starting_bet"
                  prefix="£"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-switch
                  v-model="dialogChase.payout"
                  flat
                  label="`Early payout"
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="6">
                <v-slider
                  :disabled="!dialogChase.payout"
                  v-model="dialogChase.payoutMinute"
                  label="Game minute payout"
                  max="90"
                  thumb-label="always"
                  min="70"
                ></v-slider>
              </v-col>
              <v-col cols="12" sm="6">
                <v-slider
                  :disabled="!dialogChase.payout"
                  v-model="dialogChase.payoutBetAmount"
                  label="Payout bet amount"
                  thumb-label="always"
                  max="200"
                  min="5"
                ></v-slider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogChase.show = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text :disabled="loadingOverlay" @click="chaseTeamDraw()">
            Chase
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
      <span class="ml-3">Retrieving events data...</span>
    </v-overlay>
  </div>
</template>
 <style scope>
.ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: red;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 10px 20px;
}
.ct-series-b .ct-line {
  /* Set the colour of this series line */
  stroke: green;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 10px 20px;
}
.ct-series-c .ct-line {
  /* Set the colour of this series line */
  stroke: yellow;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 10px 20px;
}
</style>

<script>
import axios from "axios";
import VueChartist from 'v-chartist';
import '../../node_modules/chartist/dist/chartist.min.css'

export default {
    components: {
        'vue-chartist': VueChartist
    },
  data: () => ({
    search: "",

    oddshistory: {
      series: [[]],
      labels: []
    },
    options: {
      distributeSeries: true,
      fullWidth: true,
      height:250,
      series: {
        'odds': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        },
        'all_avg': {
          showPoint: false,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        }
      },
  
    },

    dialogChase: {
      show: false,
      competitor_id: 0,
      competitor_name: "no name",
      starting_bet: 0,
      payout: true,
      payoutMinute: 85,
      payoutBetAmount: 80,
    },
    filterStatus: "upcoming_live",
    filterLeagues: '',
    searchByStatus: [
      { state: "Live / Upcoming", value: "upcoming_live" },
      { state: "Ended", value: "ended" },
    ],

    headers: [
      {
        text: "Home",
        value: "competitor_home_name",
        sortable: false,
      },
      {
        text: "Away",
        value: "competitor_away_name",
        sortable: false,
      },
      { text: "Status", value: "state" },
      { text: "Volume", value: "volume" },
      { text: "Odds 1 x 2", value: "odds_draw", sortable: false },
      { text: "League", value: "parent_id" },
      { text: "Start time", value: "start_datetime" },
      { text: '', value: 'data-table-expand' }
    ],

    events: [],

    loadingOverlay: false,
    leagues: [
      { id: 30089013, name: "Italy - Serie B" },
      { id: 41803384, name: "Spain - La liga 2" },
      { id: 30088286, name: "French - Ligue 2" },
      { id: 0, name: 'Germany - Lige 2'},
      { id: 28936072, name: "England - Championship"},
      { id: 29558541, name: "Turkey - Super Lig"}
    ],
  }),
  created() {
   
    this.initialize()
  },

  methods: {

    loadEventInfo(event) {
      if (event.value) {
            this.oddshistory = { 
              labels: [], 
              series: [ {name: 'odds', data:[] } ] 
            }
      this.loadingOverlay = true
      axios({
        url: "http://a.bet.lukaszk.usermd.net/v1/oddshistory/" + event.item.event_id + '/' + event.item.state + '/' + event.item.start_datetime,
        method: "get"
      })
     .then((response) => { 
        var history = response.data
        for(var i=0; history.length > i; i++) {
          this.oddshistory.labels.push(i+1)
          var odds = (event.item.state == "e2nded" ? Number(history[i].payout_precent) : Number(history[i].draw))
          this.oddshistory.series[0].data.push(odds > 20 ? 20 : odds)
        }
        this.loadingOverlay = false
      })
      

      }
    },


    filterLeaguesChange() {
      localStorage.setItem('leagues_filter',JSON.stringify(this.filterLeagues))
    },

    openChaseDlg(name, id) {
      this.dialogChase.competitor_name = name;
      this.dialogChase.competitor_id = id;
      this.dialogChase.show = true;
    },

    getPriceByMarket(market_id, type) {
      var contract_id = this.contract[
        this.contract.findIndex(
          (c) => c.contract_type.name == type && c.market_id == market_id
        )
      ].id;
      if (contract_id !== undefined) {
        if (this.quotes[contract_id].offers[0] !== undefined)
          return Number(
            10000 / this.quotes[contract_id].offers[0].price
          ).toFixed(2);
      }
      return 0;
    },
   
    findLeague(league_id) {
      return this.leagues.find((l) => l.id == league_id).name;
    },

    chaseTeamDraw() {
      this.loadingOverlay = true
      axios({
        url: "http://a.bet.lukaszk.usermd.net/v1/chased/add",
        method: "post",
        data: {
          competitor_id: this.dialogChase.competitor_id,
          competitor_name: this.dialogChase.competitor_name,
          starting_bet: this.dialogChase.starting_bet,
          payout: this.dialogChase.payout,
          payout_bet_amount: this.dialogChase.payoutBetAmount,
          payout_game_minute: this.dialogChase.payoutMinute,
        },
      })
     .then((response) => { if (response) this.dialogChase.show = false;  this.loadingOverlay = true })
    /*    .catch((error) => {})
        .then(() => {});*/
    },

    initialize() {

      this.filterLeagues = JSON.parse(localStorage.getItem('leagues_filter'));

      var url = "http://a.bet.lukaszk.usermd.net/v1/events";
      if (this.filterStatus == "ended") url += "/ended"
      else url += '/live'

      url += '/' + this.filterLeagues

      this.loadingOverlay = true;
      axios({
        url: url,
        method: "get",
      })
        .then((response) => {
          this.events = response.data;

          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });
    },
  },
};
</script>