<template>
<div>

  
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm1 md1>
              <v-text-field
                label="Min odds"
                hint="Min. sel. odds"
                v-model="couponDlg.min_odds"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm1 md1>
              <v-text-field
                label="Days"
                v-model="couponDlg.days"
                hint="Days spread"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm1 md1>
              <v-text-field
                label="Ratio"
                hint="Min. p. ratio"
                v-model="couponDlg.min_ratio"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm1 md1>
              <v-text-field
                label="P. exp."
                hint="Min. bets played"
                v-model="couponDlg.min_bets"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm1 md1>
              <v-text-field
                label="Sel. ratio"
                hint="Min. sel. ratio"
                v-model="couponDlg.min_sel_ratio"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm1 md1>
            <!--v-combobox
            v-model="selected_markets"
            :items="markets_types"
            label="Markets"
            multiple
          ></v-combobox-->
            </v-flex>
                      <v-flex md1>
              <v-checkbox
              v-model="couponDlg.history"
              label="History"
            ></v-checkbox>
            </v-flex>

            <v-flex md4>
                

          <v-btn class="mt-3 ml-1" @click="loadCouponData()">Search</v-btn>
          <v-btn class="mt-3 ml-4" @click="openCouponDlg()">Generate</v-btn>
          <v-btn class="mt-3 ml-4" @click="couponGenDlg.show = true">Strategies</v-btn>
            </v-flex>
            <v-flex md12>
              Total: <strong>{{ proposals.length }}</strong> (lost {{ proposals.filter((p) => p.won ? true : false).length }}),  
              <span v-if="couponDlg.history"> Markets won: <strong>{{ Number((proposals.filter((p) => p.won ? false : true).length/proposals.filter((p) => p.won ? true : true).length)*100).toFixed(3) }}%</strong>,</span>
              <span v-if="couponDlg.history"> Markets/lost: <strong>{{ Number(proposals.length / proposals.filter((p) => p.won ? true : false).length).toFixed(2) }}</strong>,</span>
              Total selections odds: <strong>{{ totalOdds }}</strong>,
              Avg odds.: <strong>{{ avgOdds }}</strong>,
              Odds per {{ Math.floor(proposals.length*acca_proc) }} selections: <strong>{{ accaOdds }}</strong>,
              Avg accuracy: <strong>{{ avgAcc }}%</strong>,
              Number of events today: {{ todayEventsCounter }}
              <span v-if="!couponDlg.history">, Proposals to: <strong>{{ toDate }}</strong> </span>
            </v-flex>
            <v-flex md12>
              <v-data-table
              :headers="couponMarketsHeaders"
              :items="proposals"
              hide-actions
              class="elevation-1 v-data-footer-hide"
              :must-sort="true">
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
        
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
      <span class="ml-3">Retrieving markets data...</span>
    </v-overlay>

    <v-dialog v-model="dialog" persistent max-width="600px">

  <v-card>
    <v-card-title>
      <span class="headline"></span>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <v-text-field label="Bet amount" required></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field label="Back odds" hint="Odds on bookmaker site"></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <v-text-field
              label="Lay odds"
              hint="Odds on exchange"
              persistent-hint
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<v-dialog v-model="couponGenDlg.show" persistent max-width="1150px">

  <v-card>
    <v-card-title>
      <span class="headline">Coupons generator</span>
    </v-card-title>
    <v-card-text>

      <v-container grid-list-md>
        <v-layout wrap>

                      <v-flex md9>
                        <!--v-sparkline
                        :value="chartOdds"
                        color="rgba(255, 255, 255, .7)"
                        height="100"
                        padding="24"
                        stroke-linecap="round"
                        smooth
                      >
                        <template v-slotlabel="item">
                          ${{ item.value }}
                        </template>
                      </v-sparkline-->
                  <!--div md1 v-for="(item, index) in oddsMap" :key="index"> 
                    <span v-if="index < 30 && index >= 5">
                      SEL: {{ (index > 8 ? '' : '0') + (index+1)  }}. {{ Number(item.odds) }}  {{ item.by_sel.toFixed(4) }} --  MARGIN SEL: {{ (index > 9 ? '' : '0') + (index+1) }}. {{ Number(item.odds_dep) }} BY SEL: {{ item.by_sel_dep.toFixed(4) }} <br/>
                    </span>
                  </div-->
                  <div id="generatedlg">
                        <vue-chartist id="chartOdds" :data="chartOdds" :options="options" type="Bar"></vue-chartist>
                        <vue-chartist id="chartBySel" :data="chartBySel" :options="options" type="Bar"></vue-chartist>
                  </div>
                      </v-flex>
                      <v-flex md3>

                        <!--v-flex xs2 md4>
                    <v-text-field
                    label="Min ratio"
                    hint="Min. sel. ratio"
                    v-model="couponGenDlg.min_sel_ratio"
                    persistent-hint
                    required
                  ></v-text-field>
                        </v-flex>
                        <v-flex xs2 md4>
                    <v-text-field
                    label="Min odds"
                    hint="Min. sel. odds"
                    v-model="couponGenDlg.min_sel_odds"
                    persistent-hint
                    required
                  ></v-text-field>
                        </v-flex>
                              </v-flex>
                              <v-flex xs2 md4>
                                <v-text-field
                                label="Days spred"
                                hint="Acca over days"
                                v-model="couponGenDlg.days_spread"
                                persistent-hint
                                required
                              ></v-text-field>
                                    </v-flex>
                                    <v-flex xs2 md4>
                                      <v-text-field
                                        label="Min. prof. exp."
                                        v-model="couponGenDlg.min_profile_exp"
                                        hint="Profile bets played"
                                        persistent-hint
                                        required
                                      ></v-text-field>
                                    </v-flex>
              
                                    <v-flex xs2 md4>
                                      <v-text-field
                                        label="Min. prof. ratio"
                                        v-model="couponGenDlg.min_profile_ratio"
                                        hint="Profile accuracy"
                                        persistent-hint
                                        required
                                      ></v-text-field>
                                    </v-flex-->
                                    <!--v-flex xs12 md12>
                                      <v-combobox
                                      v-model="couponGenDlg.markets"
                                      :items="markets_types"
                                      label="Markets types"
                                      multiple
                                    ></v-combobox>
                                      </v-flex-->
                                    <v-flex xs8 md12>
                                      <v-text-field
                                        label="Number of coupons"
                                        v-model="couponGenDlg.coupons_number"
                                        hint="Amount of coupons"
                                        persistent-hint
                                        required
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex xs8 md12>
                                
                                        <v-text-field
                                        label="Acca odds"
                                        hint="Min. acca. odds"
                                        v-model="couponGenDlg.min_acca_odds"
                                        persistent-hint
                                        required
                                      ></v-text-field>
                                      </v-flex>
                                      <v-flex xs12 md12>
                                      <v-text-field
                                        label="Strategy name"
                                        v-model="couponGenDlg.strategy_name"
                                        hint="Description of used settings"
                                        persistent-hint
                                        required
                                      ></v-text-field>
              
                                    </v-flex>
              
                                    </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
    <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn text @click="generateCoupon()" :loading="couponGenDlg.loading">Generate</v-btn>
        <v-btn text @click="couponGenDlg.show = false">Close</v-btn>
    
    </v-card-actions>
  </v-card>

</v-dialog>



<v-dialog v-model="historyDlg.show" persistent max-width="1250px">

  <v-card>
    <v-card-title>
      <span class="headline">History review</span>
    </v-card-title>
    <v-card-text>

      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex md12>
            <v-data-table
            :headers="couponMarketsHeadersHistory"
            :items="proposals"
            hide-actions
            class="elevation-1 v-data-footer-hide"
            :must-sort="true">
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>


    </v-card-text>
    <v-card-actions>
           <v-spacer></v-spacer>
        <v-btn text @click="historyDlg.show = false">Close</v-btn>
    
    </v-card-actions>
  </v-card>
</v-dialog>
</div>
</template>

<script>
import axios from "axios";
//import annotation from "chartjs-plugin-annotation"
import moment from "moment"

import VueChartist from 'v-chartist';
import '../../node_modules/chartist/dist/chartist.min.css'

export default {
  components: {
    'vue-chartist': VueChartist
  },
  data: () => ({
    loadingOverlay: false,
    selected_markets: [],
    markets_types: [
      "MATCH_ODDS",
      "DOUBLE_CHANCE",
      "OVER_UNDER",
      "BOTH_TEAMS_TO_SCORE",
      "AWAY_TO_SCORE",
      "HOME_TO_SCORE"
    ],
    acca_odds: 0,
    acca_proc: 0.4,
    couponGenDlg: {
      show: false,
      proposals: [],
      min_profile_ratio: 0.75,
      min_profile_exp: 50,
      min_sel_odds: 1.05,
      min_sel_ratio: 80,
      min_acca_odds: 5.0,
      coupons_number: 10,
      days_spread: 7,
      strategy_name: '',
      loading: false,
      markets: [
      "MATCH_ODDS",
      "DOUBLE_CHANCE",
      "OVER_UNDER",
      "BOTH_TEAMS_TO_SCORE",
      "AWAY_TO_SCORE",
      "HOME_TO_SCORE"
    ]
    },

    couponDlg: {
      show: false,
      min_ratio: 0.75,
      min_bets: 50,
      min_odds: 1.05,
      days: 7,
      min_sel_ratio: 80,
      history: false,
      oddssum: 0,
    },

    historyDlg: {
      show: false
    },

    couponMarketsHeadersHistory: [
   //     { text: 'LIVE', value: 'inplay'},
          { text: 'TYPE', value: 'type' },
          { text: 'SEL.', value: 'odds_name' },
          { text: 'EVENT', value: 'event_name' },
          { text: 'H.', value: 'home_score' },
          { text: 'A.', value: 'away_score' },
          { text: 'P. AVG RATIO', value: 'profile_ratio' },
          { text: 'PROP. RATIO', value: 'prop_ratio' },
          { text: 'BETS', value: 'counter' },
          { text: 'START TIME', value: 'start_at' }
    ], 

    couponMarketsGenHeaders: [
          { text: 'TYPE', value: 'type' },
          { text: 'SEL.', value: 'odds_name' },
          { text: 'ODDS', value: 'odds'},
          { text: 'PROP. RATIO', value: 'prop_ratio' },
          { text: 'BETS', value: 'counter' },
          { text: 'EVENT', value: 'event_name' },
          { text: 'START TIME', value: 'start_at' }
    ], 

    couponMarketsHeaders: [
          { text: 'STATUS', value: 'status' },
          { text: 'LOST', value: 'won'},
          { text: 'ACC', value: 'acc' },
          { text: 'RESULT', value: 'result_d' },
          { text: 'PSP.', value: 'ss_ps_pos' },     
          { text: 'TYPE', value: 'type' },
          { text: 'SEL.', value: 'odds_name' },
          { text: 'EVENT NAME', value: 'event_name' },
          { text: 'T.NAME', value: 'tournament_name'},
          { text: 'T.COUNTRY', value: 'tournament_cat'},
          { text: 'A.ODDS', value: 'odds_display' },
          { text: 'C.ODDS', value: 'odds_change' },
          { text: 'P.AVG R', value: 'profile_ratio' },
          { text: 'P.TOP.R', value: 'top_ratio' },
          { text: 'MARKET.R.', value: 'prop_ratio' },
          { text: 'POP.', value: 'counter' },
          { text: 'START TIME', value: 'start_at' },
    ], 

    menu: false,
    date: moment(new Date()).format('YYYY-MM-DD'),
    top_ones: false,
    min_ratio: 0.75,
    min_played: 70,
    prop_range: [1.0, 1.8],
    chased: [],
    filtered: [],

    proposals:  [],
    statistics: [],
    tournaments:[],

    chartOdds: [],
    chartBySel: [],
  
    data: {
      labels: [],
      series: [],
      datasets: [
        {
          label: '',
          backgroundColor: '#f87979',
          data: []
        }
      ]
    },
    options: {
 //     seriesBarDistance: 20,
      height:310,
      //low:0,
    reverseData: true,
    horizontalBars: true,
    axisY: {
      offset: 120
    }
    },

  }),
  created() {
    this.loadCouponData() 
  },
  mounted() {

  },
  computed: {


    oddsMap: function () {
      var odds_map = []
      for(var i=0;i < this.statistics.odds_map.length; i++) {
        odds_map.push(this.statistics.odds_map[i])
      }
      return odds_map
    },


    chartData: function () {
     // var lost_per_markets = Number(this.proposals.length / this.proposals.filter((p) => p.won ? true : false).length).toFixed(0);
          var data =  {
            series: [{name: 'odds', data:[]}],
            labels: [],
          }
          for(var i=5;i < 40; i++) {
            data.series[0].data.push(parseFloat(this.statistics.odds_map[i].by_sel))
         // data.series[1].data.push(odds_map[c].odds)
            data.labels.push(i+1)
          }
/*    var td = {
        labels: [],
        datasets: [],
        series: [[]]

      }
    
      for (var i = 5; i < 15; i++) {
        var stat = this.statistics.odds_map[i]
        td.labels.push(i)
        td.series[0].push(stat.odds)
        td.datasets.push({
          label: i+1,
          backgroundColor: 'blue',
          data: stat.odds
        })
      }*/
      
      return data
    },

    marketsToLost: function () {
      return  Number(this.proposals.length/(this.proposals.length-(this.proposals.length))).toFixed(4)
    },

    toDate: function () {
      var date = moment().add(this.couponDlg.days-1, 'days')
      return (date).format("dddd") + ' ('+ date.format('DD-MM') + ')'
    },

    marketsLost: function () {
      return Number(this.proposals.filter((p) => p.won ? false : true)).length
    },
    marketsWon: function () {
      return Number(this.proposals.filter((p) => p.won ? true : false)).length
    },
    totalOdds: function () {
      var odds = 1
      for (var i = 0; i < this.proposals.length; i++) {
        odds = odds * this.proposals[i].odds
      }
      return Number(odds).toFixed(2)
    },

    todayEventsCounter: function () {
      var events = this.proposals.filter((p) => moment(p.start_at).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY'))
      return events.length
    },

    avgOdds: function () {
      var odds = 0
      for (var i = 0; i < this.proposals.length; i++) {
        var ex_odds   = parseFloat(this.proposals[i].ex_odds)
        var s_odds    = parseFloat(this.proposals[i].odds)
        var u_odds    = s_odds
        if (this.proposals[i].status == 'notstarted') {
          u_odds = (ex_odds > 0 && s_odds/ex_odds > 0.85 && ex_odds/s_odds < 1.15 ? ex_odds : s_odds)
        }
        //console.log(odds)
        odds = odds + u_odds
      }
      return Number(odds / this.proposals.length).toFixed(4)
    },

    oddsTable: function () {
      var table = []
      var odds = 1
      var per_market  = Number(this.proposals.length/(this.proposals.length-(this.proposals.length*0.945))).toFixed(4)
      for (var a = 0; a < per_market+30; a++) {
       // var ex_odds   = this.proposals[i].ex_odds
       // var u_odds    = (ex_odds > 0 && s_odds/ex_odds > 0.85 && ex_odds/s_odds < 1.15 ? ex_odds : s_odds)
        odds = parseFloat(odds) * parseFloat(this.avgOdds)
        if (a > 4) {
        table.push({
          selections: a+1,
          odds: odds
        })
        }
      }
      return table
    },

    accaOdds: function () {
      var odds = 1
      for (var i = 0; i < Math.floor(this.proposals.length*this.acca_proc); i++) {
        odds = odds * Number(this.avgOdds)
      }
      return Number(odds).toFixed(2)    
    },

    avgAcc: function () {
      var acc = 0
      for (var i = 0; i < this.proposals.length; i++) {
        if (Number(this.proposals[i].acc) > 0) {
          acc = acc + Number(this.proposals[i].acc)
        }
      }
      return Number(acc / this.proposals.length).toFixed(0)
    },
    // a computed getter
    mostPopular: function () {
      var popular = []
      for(var e=0; e < this.chased.length; e++){
        var event = this.chased[e]
        var arr = Object.values(event.bet);
        var added = false;
        for(var i=0; i <arr.length; i++) {
          var type = arr[i].oddsName.toString()
          if (type === '1X' || type === 'X2' || type === '12') { // || type === '1' || type === '2') {
            if (!added && arr[i].bets_counter >= 1 && arr[i].odds >= 1.2) {
              added = true
              popular.push(event)
            }
          } 
        }
      }
      return popular
    },
    proposalsFiltered: function () {
      var filtered = []
      for(var e=0; e < this.proposals.length; e++) {
        var prop = this.proposals[e]
        var type = prop.type
              if (this.selected_markets.find((m) => m.type === "DOUBLE_CHANCE") 
                   && (type === '1X' || type === 'X2' || type === '12')) { // || type === '1' || type === '2') {
                  filtered.push(prop)
              } else if (this.selected_markets.find((m) => m.type === "MATCH_ODDS") 
                   && (type === '2' || type === 'X' || type === '1')) { // || type === '1' || type === '2') {
                  filtered.push(prop)
              } else if (this.selected_markets.find((m) => m.type.substr(0,10) === "OVER_UNDER")) {
                  filtered.push(prop)
              }
            }
        
      return filtered
    }
  },
  methods: {

    openCouponDlg() {
      this.couponGenDlg.show = true; 
      document.querySelector('#chartOdds').resize()
      document.querySelector('#chartBySel').resize()
    },

    generateCouponMarkets() {

    },

    getAccaOdds(legs) {
      var odds = 0;
      for(var i=0;i<legs;i++) {
        odds = odds * this.avgOdds
      }
      return odds
    },

    getOponents(event) {
        return { away: event.eventName.split(" - ")[1], home: event.eventName.split(" - ")[0] }
    },

    countProposals(event) {
      return event.bet.length;
    },

    filterMarkets() {
      console.log('Filtred')

      var bets = [];
      for(var i=0; i < this.chased.length; i++) {
        var event = this.chased[i]
        var has = false
        for(var b=0; b < event.bet.length; b++) {
          var type = event.bet[b].oddsName.toString()
          if (type === '1X' || type === 'X2' || type === '12' || type === '1' || type === '2' || type === 'X') {
            if (parseInt(event.bet[b].profile_ratio*100) >= parseInt(this.min_ratio*100) && /*
                parseInt(event.bet[b].profile_bets) >= parseInt(this.min_played) && */
                event.bet[b].odds > this.prop_range[0] && 
                event.bet[b].odds < this.prop_range[1]) {
              has = true
            }
          }
        }
        var dt_now = moment(new Date(event.startAt))
    //    var diff = dt_now.diff(moment(this.date), 'days')
        if (has && dt_now.format('YYYY-MM-DD') === this.date.substring(0,10)) { // diff < 2 && diff >= 0) {
          event.startAt = moment(event.startAt).format('DD/MM HH:mm') 
          bets.push(event)
        }
      }  
 //     var result = bets
    //  if (this.top_ones) {
        bets.sort(function(a,b) {
            var score_a = a.prob.home > a.prob.away ? a.prob.home - a.prob.away : a.prob.away - a.prob.home 
            var score_b = b.prob.home > b.prob.away ? b.prob.home - b.prob.away : b.prob.away - b.prob.home  
            return score_b - score_a
        });
   //   }
      console.log(bets.length)

      this.filtered = bets
    },


    groupBets(bets) {
    //  return bets /*
      var groups = []
      bets = Object.values(bets)
      for(var i=0;i<bets.length;i++) {


  if (parseInt(bets[i].profile_ratio*100) >= parseInt(this.min_ratio*100) && 
    parseInt(bets[i].profile_bets) >= parseInt(this.min_played) ) {

        var entry = groups.find((v) => bets[i].oddsName == v.oddsName && bets[i].type === v.type)
        if (!entry) {
          entry = bets[i] 
          groups.push(entry)
          entry.bets_counter = 1
          entry.top_ratio = entry.profile_ratio 
          entry.avg_odds = parseFloat(entry.odds)
          entry.avg_ratio = parseFloat(entry.profile_ratio)
        } else {
          entry.top_ratio = parseInt(entry.top_ratio*100) < parseInt(entry.profile_ratio*100) ? entry.profile_ratio : entry.top_ratio
          entry.bets_counter += 1
          entry.avg_odds = entry.avg_odds + parseFloat(entry.odds)
          entry.avg_ratio = entry.avg_ratio + parseFloat(entry.profile_ratio)
        }
        
  }


      }
      return groups 
    },

    proposalOver12(event) {
      var odds = 0
      var arr = Object.values(event.bet);
      for(var i=0; i <arr.length; i++) {
        if (arr[i].odds > 1) {
          var type = arr[i].oddsName.toString()
          if (type === '1X' || type === 'X2' || type === '12') {
            odds = arr[i].odds
          }
        }
      }
      return odds
    },


    loadOrders() {

    },
    generateCoupon() {  
      this.couponGenDlg.loading = true
      var url = "https://a.bet.lukaszk.usermd.net/v1/coupon"
      axios({
        url: url,
        method: "post",
        data: {
          min_profile_ratio:  parseFloat(this.couponDlg.min_ratio),
          min_profile_exp:  parseFloat(this.couponDlg.min_bets),
          min_sel_ratio: parseFloat(this.couponDlg.min_sel_ratio),
          min_sel_odds: parseFloat(this.couponDlg.min_odds),

          min_acca_odds: parseFloat(this.couponGenDlg.min_acca_odds),
          days_spread: parseInt(this.couponDlg.days),
          coupons_number: parseInt(this.couponGenDlg.coupons_number),
          strategy_name: this.couponGenDlg.strategy_name,
          markets: this.couponGenDlg.markets,
          history: this.couponDlg.history
        }
      })
        .then(() => {
          this.couponGenDlg.loading = false
          /*
          this.acca_odds = response.data.odds
          this.couponGenDlg.proposals = response.data.selections
          this.couponGenDlg.proposals.sort(function(a,b) {
            return (new Date(a.start_at)) - (new Date(b.start_at))
          });
        
          this.couponGenDlg.extras = response.data.extras
          */

          this.couponGenDlg.show = false
        })
        .catch((error) => {
          this.couponGenDlg.loading = false
          console.log(error);
        });
    },

    refreshStats() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/couponmarkets"
      axios({
        url: url,
        method: "post",
        data: {
          profile_bets: parseFloat(this.couponDlg.min_bets),
          profile_ratio: parseFloat(this.couponDlg.min_ratio),
          days: parseInt(this.couponDlg.days),
          min_odds: parseFloat(this.couponDlg.min_odds),
          min_prop: parseFloat(this.couponDlg.min_prop),
          history: this.couponDlg.history,
          min_sel_ratio: parseFloat(this.couponDlg.min_sel_ratio)
        }
      }).then((response) => {
          this.couponGenDlg.proposals = response.data.filtered
        //  this.tournaments = response.data.tournaments_categories

      }).catch((error) => {
          console.log(error)
      });
    },



    loadCouponData() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/couponmarkets"
      this.loadingOverlay = true
      axios({
        url: url,
        method: "post",
        data: {
          profile_bets: parseFloat(this.couponDlg.min_bets),
          profile_ratio: parseFloat(this.couponDlg.min_ratio),
          days: parseInt(this.couponDlg.days),
          min_odds: parseFloat(this.couponDlg.min_odds),
          min_prop: parseFloat(this.couponDlg.min_prop),
          history: this.couponDlg.history,
          min_sel_ratio: parseFloat(this.couponDlg.min_sel_ratio)
        }
      }).then((response) => {
          this.proposals    = response.data.filtered
          this.tournaments  = response.data.tournaments_categories
          this.statistics   = response.data.stats
          this.proposals.sort(function(a,b){
            return  b.prop_ratio - a.prop_ratio
          }); 
          for (var i=0; i < this.proposals.length; i++) {
            this.proposals[i].won = (this.proposals[i].won ? '' : (this.proposals[i].status === 'inprogress' ? 'LOSING' : 'LOST'))
            this.proposals[i].acc = this.proposals[i].acc == -1 ? '' : this.proposals[i].acc 
            this.proposals[i].odds_display = (this.proposals[i].ex_odds > 0 ? this.proposals[i].ex_odds : this.proposals[i].odds)
            this.proposals[i].result_d  = '('+this.proposals[i].home_score + ' : ' + this.proposals[i].away_score + ')'
            this.proposals[i].ss_ps_pos = (this.proposals[i].home_ss_ps_pos < 10 ? '' : '' ) + this.proposals[i].home_ss_ps_pos  + '-' + (this.proposals[i].away_ss_ps_pos < 10 ? '' : '' ) + this.proposals[i].away_ss_ps_pos
            let odds_change = this.proposals[i].ex_odds > 0 ? Number(parseFloat(this.proposals[i].ex_odds)-parseFloat(this.proposals[i].odds)).toFixed(2) : '-'
            this.proposals[i].odds_change = (odds_change > 0 ? '+' : '') + odds_change
      //      this.proposals[i].acc = this.calculateAccuracy(this.proposals[i]) 
          }
      
            this.chartOdds =  {
              series: [{name: 'a', data:[]},{name: 'b', data:[]}],
              labels: [],
            }
            this.chartBySel =  {
              series: [{name: 'a', data:[]},{name: 'b', data:[]}],
              labels: [],
            }
            for(var c=0;c < 35; c++) {
              
              this.chartOdds.series[0].data.push(parseFloat(this.statistics.odds_map[c].odds_dep))
              // data.series[1].data.push(odds_map[c].odds)
              this.chartOdds.labels.push(c+1)
              
              this.chartBySel.series[1].data.push(parseFloat(this.statistics.odds_map[c].by_sel_dep))
              this.chartBySel.labels.push(c+1)
           //  this.chartOdds.push(parseFloat(this.statistics.odds_map[c].odds_dep))
            }
          this.loadingOverlay = false
      }).catch((error) => {
          console.log(error)
          this.loadingOverlay = false
      });
    },




    loadSofascore(eventname) {
      var url = "https://a.bet.lukaszk.usermd.net/v1/sofascore/" + eventname;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          var event = this.chased.find((c) => c.eventName == eventname)
          event.homeScore = response.data.homeScore.aggregated ? response.data.homeScore.aggregated  : response.data.homeScore.current
          event.awayScore = response.data.awayScore.aggregated ? response.data.awayScore.aggregated  : response.data.awayScore.current
          event.league = response.data.tournament.name
        })
        .catch((error) => {
          console.log(error);
        });
    },
/*
    initialize() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/proposals";
      this.loadingOverlay = true;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          this.chased = response.data;
          for(var i=0;i<this.chased.length;i++) {
            this.calculatePropability(this.chased[i])
            this.chased[i].ss = {}
          }
          this.chased.sort(function(a,b) {
            return (new Date(a.startAt)) - (new Date(b.startAt))
          });
        
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });
    },
*/
  },
};
</script>
<style scope>
.ct-horizontal-bars {
  margin:3px 3px !important;
}
.ct-series-a .ct-bar {
  /* Colour of your bars */
  stroke: blue;
  /* The width of your bars */
  stroke-width: 2px;
  stroke-dashoffset: 0px;
  stroke-dasharray: 0px 0px;
  /* Maybe you like round corners on your bars? */
  stroke-linecap:square !important;
}
.ct-series-b .ct-bar {
  /* Colour of your bars */
  stroke: red;
  /* The width of your bars */
  stroke-width: 2px;
  stroke-dashoffset: 0px;
  stroke-dasharray: 0px 0px;
  /* Maybe you like round corners on your bars? */
  stroke-linecap:square !important;
}
.ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: red;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 8px 2px;
}
.ct-series-b .ct-line {
  /* Set the colour of this series line */
  stroke: green;
  /* Control the thikness of your lines */
  stroke-width: 8px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 8px 2px;
}
.ct-series-c .ct-line {
  /* Set the colour of this series line */
  stroke: blue;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
</style>