import Vue from 'vue'
import VueRouter from 'vue-router'
import Chased from '../views/Chased.vue'
import Watchlist from '../views/Watchlist.vue'
import Events from '../views/Events.vue'
import History from '../views/History.vue'
import Proposals from '../views/Proposals.vue'
import Matcher from '../views/Match.vue'
import Coupons from '../views/Coupons.vue'
import Cashout from '../views/Cashout.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Chased',
    component: Chased
  },
  {
    path: '/cashout',
    name: 'Cashout',
    component: Cashout
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
    component: Watchlist
  },
  {
    path: '/events',
    name: 'Events',
    component: Events
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/proposals',
    name: 'Proposals',
    component: Proposals
  },
  {
    path: '/matcher',
    name: 'Odds macher',
    component: Matcher
  },
  {
    path: '/coupons',
    name: 'Coupons',
    component: Coupons
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
