<template>
<div>
 
  <v-overlay :value="loadingOverlay">
    <v-progress-circular indeterminate size="32"></v-progress-circular>
    <span class="ml-3">Retrieving coupons data...</span>
  </v-overlay>
    <v-layout wrap>
      <v-flex md8>
    Summary odds: {{ Number(couponsOdds()).toFixed(2) }}, Bet value: <strong>{{ getStats().bet }}&pound;</strong>, 
       tickets won: <strong class="green--text darken-5">{{ getStats().won }}</strong>, tickets lost: <strong class="red--text darken-3">{{  getStats().lost }}</strong>, spended: {{ getStats().spended }}&pound;, 
       return: {{ getStats().margin_return }}&pound;, profit: <strong>{{ getStats().margin_profit }}&pound;</strong>
        (<strong>{{ Number(getStats().margin_ratio*100).toFixed(0) }}%</strong> / {{ Number(getStats().ratio*100).toFixed(0) }}%), odds margin: <strong>-{{ Number(100-(getStats().margin*100)).toFixed(2) }}%</strong>
     
      </v-flex>
      <v-flex md4>
        <v-combobox
    
        v-model="bookie"
        :items="bookies"
        label="Bookie"
        single
      ></v-combobox>
      </v-flex>
      <v-flex md6 sm12 lg4 class="pa-2" v-for="item in coupons" :key="item">
        <v-card :style="'border-left:6px solid '+(isWon(item) ? '#4DD5A8' : '#F8F1E9')">
          <v-card-title>
            {{ item.selections.length }}
            #{{ item.coupon_id }} Coupon  @{{ Number(getAccaOdds(item.selections).toFixed(2)) }} / {{ getAccaAcc(item.selections) }}% R{{ getAccaRatio(item.selections) }} 
            <v-card-subtitle>{{ item.created_at }}</v-card-subtitle>
          </v-card-title>
          <v-card-text>
    
                <v-row md12 v-for="(selection, index) in item.selections" :key="index" :style="'background-color: ' + (index % 2 ? 'white' : '#f0f0f0')" class="ma-2">
                  <v-layout wrap>
                  <v-flex md7><strong>{{ ((index > 8 ? '' : '0') + (index+1))  }}.</strong>
                  {{ selection.start_at.substr(8,2) + '-' + selection.start_at.substr(5,2) }}
                 <v-icon size="18" :color="getSelectionStatusColor(selection)">{{ getSelectionStatusIcon(selection) }}</v-icon>
                 <a target="_blank" :href="'http://href.li/https://a.bet.lukaszk.usermd.net/v1/redirect/' + bookie + '/' + selection.event_name + '/' + selection.start_at.substr(0,10)">
                  {{ selection.event_name.substr(0,25) + (selection.event_name.length > 25 ? '...' : '')  }}</a>
                   <span v-if="selection.status != 'notstarted'">  (<strong>{{ selection.home_score }}</strong>:<strong>{{ selection.away_score }}</strong>) </span>
                  </v-flex>
                  <v-flex md3>
                    {{ selection.type.replace("_"," ") }}
                  </v-flex>
                  <v-flex md1>{{ selection.odds_name }}</v-flex>
                  <v-flex md1>{{ (selection.ex_odds > 0 ? selection.ex_odds + '*' : selection.odds) }}</v-flex>

                  <!--v-flex md12>{{ startsIn(selection) }}</v-flex-->
                </v-layout>
      </v-row>
            
          </v-card-text>
          <v-card-actions style="background-color:#eee;">

          <v-spacer></v-spacer>
            <v-btn text @click="item.betting = !item.betting">Bet</v-btn>
          </v-card-actions>
        </v-card>
      
      </v-flex>
    </v-layout> 

    <!--v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
      <span class="ml-3">Retrieving events data...</span>
    </v-overlay-->

</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
//import annotation from "chartjs-plugin-annotation"

export default {

  data: () => ({
    baseBetValue: 4,
    bookie: 'Netbet',
    bookies: [
      "Smarkets",
      "WilliamHill",
      "Betfred",
      "Coral"
    ],
    loadingOverlay: false,
    coupons: [],
    markets: [],
    profit_margin: 0.8

  }),
  created() {
    this.initialize()
  },
  mounted() {

  },
  computed: {

  },
  methods: {
    getSelectionStatusIcon(selection) {
      if (selection.won == 1 && selection.status !== 'inprogress')
        return "mdi-check-circle"
      if (selection.status == 'inprogress')
        return "mdi-stop-circle-outline"
      if (selection.status !== 'inprogress' && selection.won !== 0 && selection.won !== 1)
        return "mdi-stop-circle-outline"
      if (selection.won == 0 && selection.status !== 'inprogress')
        return "mdi-close-circle"
      return "mdi-stop"      
    },

    getSelectionStatusColor(selection) {
      if (selection.won == 1 && selection.status !== 'inprogress')
        return "green"
      if (selection.status == 'inprogress')
        return "orange"
      if (selection.status !== 'inprogress' && selection.won !== 0 && selection.won !== 1)
        return "grey"
      if (selection.won == 0 && selection.status !== 'inprogress')
        return "red"
      return "grey"      
    },
       
    
    couponsOdds() {
      var sumodds = 0
      for(var c1=0; c1< this.coupons.length;c1++) {    
        sumodds += this.getAccaOdds(this.coupons[c1].selections)
      }
      return sumodds
    },


    startsIn(sel) {
       return moment().countdown(sel.start_at).toString()
    },


    getAccaRatio(selections) {
      var odds = this.getAccaOdds(selections)
      return Number(odds / selections.length).toFixed(2)
    },

    getStats() {
      var stats = {
        won: 0,
        lost: 0,
        avg_acc: 0,
        spended: 0,
        return: 0,
        profit: 0,
        bet: 0,
      }

      stats.bet = this.baseBetValue

      var avg_c_odds = 0;
      for(var c1=0; c1< this.coupons.length;c1++) {
        var coupon_odds = 1
        for(var s1=0;s1<this.coupons[c1].selections.length;s1++){
          var sel_odds = parseFloat(this.coupons[c1].selections[s1].ex_odds) ? parseFloat(this.coupons[c1].selections[s1].ex_odds) : parseFloat(this.coupons[c1].selections[s1].odds);
          coupon_odds = coupon_odds * sel_odds
          //console.log(sel_odds + " codds: " + coupon_odds)
        }
        avg_c_odds += parseFloat(coupon_odds)
      }
      avg_c_odds = (parseFloat(avg_c_odds) / this.coupons.length)


      for(var c=0; c< this.coupons.length;c++) {
        var coupon = this.coupons[c]
        let won = true
        var odds = 1
        for(var s=0;s<coupon.selections.length;s++){
            var ex_odds   = coupon.selections[s].ex_odds
            var s_odds    = coupon.selections[s].odds
            var u_odds    = (ex_odds > 0 ? ex_odds : s_odds)
            odds = odds * u_odds
            if (!coupon.selections[s].won)
              won = false
        }
        var bet = (stats.bet * (avg_c_odds / odds))

        stats.won += (won ? 1 : 0)
        stats.lost += (won ? 0 : 1)
        if (won) {
          stats.return += bet * odds
          coupon.return = bet * odds
        }
        stats.spended += bet

        console.log('ODDS ' + Number(odds).toFixed(2) + ' BET: ' + bet)
      }
      stats.return  = Number(stats.return).toFixed(2)
      stats.profit  = Number((stats.return - stats.spended)).toFixed(2)
      stats.ratio   = Number((stats.profit / stats.spended)).toFixed(2)
      stats.spended = Number(stats.spended).toFixed(2)
      stats.margin_return   = Number(stats.return * this.profit_margin).toFixed(2)
      stats.margin_profit   = Number((stats.return - stats.spended) * this.profit_margin).toFixed(2)
      stats.margin_ratio    = Number((stats.profit / stats.spended) * this.profit_margin).toFixed(2)
      stats.margin          = this.profit_margin
      return stats
    },

    getAccaOdds(selections) {
      var odds = 1
      for(var i=0;i<selections.length;i++) {
        var ex_odds   = selections[i].ex_odds
        var s_odds    = selections[i].odds
        var u_odds    = (ex_odds > 0 ? ex_odds : s_odds)
        odds          = odds * u_odds
      }
      return odds
    },

    getAccaAcc(selections) {
      var acc = 1
      for(var i=0;i<selections.length;i++) {
        acc += selections[i].acc
      }
      return Number(acc / selections.length).toFixed(0)
    },

    isWon(coupon) {
      var s = coupon.selections
      var won_c = 0;
      for(var i=0; i < s.length; i++) {
        if (s[i].won)
          won_c++
      }
      return (won_c >= s.length ? true : false)
    },

    loadCouponData() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/couponsdata"
      axios({
        url: url,
        method: "post",
        data: {
          coupons: this.coupons
        }
      }).then((response) => {
          this.markets = response.data
      }).catch((error) => {
          console.log(error)
        });
    },

    initialize() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/coupons";
      this.loadingOverlay = true;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          this.coupons = response.data
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });



    },
  },
};
</script>