<template>
<div>
  <v-row class="ma-4">
    Total amount locked: {{ totalSpent }}&pound;, total matches chased: {{ totalMatches }},
     avg. matches per chased team: {{ Number(totalMatches/chased.length).toFixed(2) }}, avg. exposure per team: {{ Number(totalSpent/ this.chased.length).toFixed(2) }}&pound;,
     expected nearby exposure {{ totalBetsAmount }}
  </v-row>
  <v-row  no-gutters>
    <v-col cols="4">
    <vue-chartist :data="chasedExposure" :options="options2" type="Bar"></vue-chartist>
    </v-col>
  </v-row>
  <v-row justify="center" class="mt-4">
    <v-expansion-panels popout>
      <v-expansion-panel
        v-for="(item,i) in chased"
        :key="i"
        @click="loadOrders(item.chased_id,20)"
      >
  
        <v-expansion-panel-header >
        <v-row no-gutters>
          <v-col cols="4">
          {{ item.chased_id }} -  <span v-if="item.matches != undefined">(<strong>{{ item.matches }}</strong>)</span> <strong class="body-1">  {{ item.competitor_name }} </strong><br/>
           <i v-if="item.matches == 0 || item.matches == undefined || !item.outcome || 1==1"><span class="mt-3">{{ item.last_status }}</span></i>
          </v-col>
          <v-col
            cols="8"
          >

              <v-row
                v-if="item.matches > 0"
                no-gutters
                style="width: 100%"
              >
                <v-col cols="2">
                   <strong v-if="item.start_datetime_diff >= 0">{{ start_datetime_diff(item.start_datetime_diff) }} </strong> <v-icon v-if="item.start_datetime_diff == 0" medium color="green darken-1">mdi-clock-fast</v-icon>
                </v-col>
                <v-col cols="2">
                   <strong>{{ item.amount_spent || '0' }}&pound;</strong> ({{Number(item.amount_staked-item.amount_spent).toFixed(3)}}&pound;, {{Number(100-Number(item.amount_spent/item.amount_staked).toFixed(2)*100).toFixed(2)}}%)
                </v-col>
                <v-col cols="2">
                   <strong>x{{ item.bet_multiplier }}</strong>
                </v-col>
                <v-col cols="2">
                  Fees: <strong>{{ Number(item.fee_payed).toFixed(2) }}&pound; </strong>
                </v-col>
                <v-col cols="2">
                  Last bet ({{ Number(10000/item.last_price).toFixed(2) }}): <strong>{{ Number(item.last_quantity).toFixed(2) }}&pound; </strong> 
                </v-col>
                <v-col cols="2">
                  Profit: <strong>{{ Number(((Number(10000/item.last_price).toFixed(2) * item.last_quantity) - item.amount_spent)).toFixed(2) }}&pound;</strong> 
                        ({{ Number(((Number(10000/item.last_price).toFixed(2) * item.last_quantity) - item.amount_spent)/item.matches).toFixed(2) }}&pound;) 
                </v-col>
              </v-row>
             
          </v-col>
        </v-row>
        
 
        </v-expansion-panel-header>
        <v-expansion-panel-content>  

              <v-row
                v-if="item.matches > 0"
                no-gutters
                style="width: 100%"
              >
                          <v-col cols="4">
                            Exposure
                <vue-chartist :data="exposure" :options="options" type="Line"></vue-chartist>
                </v-col>
                <v-col cols="4">
              Exposure
              <vue-chartist :data="simulation1" :options="options" type="Line"></vue-chartist>
                </v-col>
                <v-col cols="4">
                  Profit to exposure
       <vue-chartist :data="simulation2" :options="options" type="Line"></vue-chartist>
                </v-col>
              </v-row>

                            <v-row
                v-if="item.matches > 0"
                no-gutters
                style="width: 100%"
              >
                          <v-col cols="4">
                            Total profit
                <vue-chartist :data="exposureB" :options="options" type="Line"></vue-chartist>
                </v-col>
                <v-col cols="4">
                  Total profit
              <vue-chartist :data="simulation1B" :options="options" type="Line"></vue-chartist>
                </v-col>
                <v-col cols="4">
     
                </v-col>
              </v-row>
               <v-row
                v-if="item.matches >= 0"
                no-gutters
                style="width: 100%"
              >
              </v-row>
                              <v-col cols="6">
       <vue-chartist :data="simulation2B" :options="options" type="Line"></vue-chartist>

               <v-select
               v-model="selectedYr"
          :items="items"
          label="Standard"
          width="50"
          @change="loadOrders(item.chased_id)"
          dense
        ></v-select>

        Total draws: {{ totalDraws }}
                </v-col>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="32"></v-progress-circular>
      <span class="ml-3">Retrieving events data...</span>
    </v-overlay>
</div>
</template>
<!--style scope>
.ct-series-a .ct-bar {
  /* Colour of your bars */
  stroke: red;
  /* The width of your bars */
  stroke-width: 8px;
  stroke-dashoffset: 0px;
  /* Maybe you like round corners on your bars? */
  stroke-linecap:square !important;
}
.ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: red;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
.ct-series-b .ct-line {
  /* Set the colour of this series line */
  stroke: green;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
.ct-series-c .ct-line {
  /* Set the colour of this series line */
  stroke: blue;
  /* Control the thikness of your lines */
  stroke-width: 2px;
  /* Create a dashed line with a pattern */
  stroke-dasharray: 5px 2px;
}
</style-->
<script>
import axios from "axios";
//import annotation from "chartjs-plugin-annotation"

import VueChartist from 'v-chartist';
import '../../node_modules/chartist/dist/chartist.min.css'

export default {
  components: {
    'vue-chartist': VueChartist
  },
  data: () => ({
    orders: [],
    simulation1: {},
    simulation2: {},
    exposure: {},

    simulation1B: {},
    simulation2B: {},
    exposureB: {},

    chasedExposure: {},

    selectedYr: 20,
    stats: [],
    items: ['16','17','18','19','20','21','22'],
    options: {
      distributeSeries: true,
      fullWidth: true,
      height:250,
      series: {
        'exp': {
          showPoint: true,
         // lineSmooth: this.$chartist.Interpolation.simple(),
          showArea: true
        },
      },
     low:0,
      tooltips: {
        mode: "index",
        intersect: true
      },
    },
    options2: {
      seriesBarDistance: 10,
      height:250,
     low:0,
  //reverseData: true,
  horizontalBars: true,
  axisY: {
    offset: 70
  }
    },
    chased: [],
    loadingOverlay: false,
    leagues: [
      { id: 30089013, name: "Italy - Serie B" },
      { id: 41803384, name: "Spain - La liga 2" },
      { id: 30088286, name: "French - Ligue 2" },
    ],
  }),
  created() {
    this.initialize();
  },
  computed: {
    // a computed getter
    totalSpent: function () {
        var total=0
        this.chased.forEach(function(item) { total += Number(item.amount_spent) })
        return Number(total).toFixed(2);
    },

    totalMatches: function() {
        var total=0
        this.chased.forEach(function(item) { total += Number(item.matches) }) 
        return Number(total)
    },

    totalBetsAmount: function() {
        var total=0
        this.chased.forEach(function(item) { total += Number(item.last_quantity).toFixed(2) }) 
        return Number(total)
    },

    totalDraws: function () {
      var total = 0;
      for(var i=0;i<this.stats.length;i++) {
        total += this.stats[i]
      }
      return total
    }

  },
  methods: {
    loadOrders(chased_id) {

       axios({
        url: "https://a.bet.lukaszk.usermd.net/v1/drawstats/league2/20"+ this.selectedYr  +"/90/99",
        method: "get"
      })
        .then((response) => {

          var data = this.stats =  response.data;

          this.simulation2B =  {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }

          for(var i=data.length-10;i >= 0 ;i--) {
            //var teams = data[i]
         //   this.simulation2B.labels.push(Number(i)+1)
           // this.simulation2B.series[0].data.push(teams)
           /* for(var a=0;a<team.draw_by.length;a++) {
              alert(team.draw_by[a])
              this.simulation2B.series[0].data[team.draw_by[a]] += 1
            }*/
            
          }



      var url = "https://a.bet.lukaszk.usermd.net/v1/chased/orders/" + chased_id;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {

          var data = this.orders = response.data;

          this.exposure =  {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }
          this.exposureB = {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }
          this.simulation2 = {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }
          this.simulation2B =  {
            series: [{name: 'exp', data: []},{name: 'b', data:[]},{name: 'c', data:[]}],
            labels: [],
          }

          var exp_c = 0;
          for(var i=0;i < data.length;i++) {
            var order = data[i]
            exp_c += Number(order.normalize_quantity)

            price = (10000/order.price)


            this.exposure.series[0].data.push(exp_c)
            this.exposure.labels.push(Number(i)+1)

            this.exposureB.series[0].data.push(order.normalize_quantity*price-exp_c)
            this.exposureB.labels.push(Number(i)+1)

            this.simulation2.series[0].data.push(((order.normalize_quantity*price-exp_c)/exp_c)*100)


            // this.simulation2B.series[0].data.push(this.stats[i]*((order.normalize_quantity*price)-exp_c))
          }


       //   var chased =  this.chased.find((c) => c.chased_id == chased_id);

//alert(chased.starting_bet)
          this.simulation1 =  {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }
          this.simulation1B = {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }
          //var price_avg = 0;
          exp_c = 0;
     
          var current_bet = 0.2
          //var liability = 0
          var balance = current_bet * 2000
          for( i=0;i <22/* data.length+3*/;i++) {
            order = data[i]
            var price = 3.1
            if (order == undefined) {
              order = {price: data[Math.floor(Math.random() * data.length)].price}
            } else { 
              price = (10000/order.price)
           //   price_avg += price
            }
           // current_bet = current_bet*1.5
            var odds = 2 //2.8 - (2.8*((i+1)/40)) 
            var bet_amount = current_bet*(odds/price)
            exp_c += Number(bet_amount)
        
            balance += this.stats[i]*((bet_amount*price)-exp_c)-(bet_amount*price)*0.01;

            this.simulation1.series[0].data.push(exp_c)
            this.exposure.series[1].data.push(exp_c)
            this.simulation1.labels.push(Number(i)+1)

            this.exposureB.series[1].data.push((bet_amount*price)-exp_c)
            this.simulation1B.series[0].data.push((bet_amount*price)-exp_c)
            this.simulation1B.labels.push(Number(i)+1)

            this.simulation2B.labels.push(this.stats[i])
            
           
            this.simulation2.series[1].data.push((((bet_amount*price)-exp_c)/exp_c)*100)

            
            this.simulation2B.series[0].data.push(balance)
            this.simulation2B.series[1].data.push(this.stats[i]*((bet_amount*price)-exp_c))
            this.simulation2B.series[2].data.push((this.stats[i]*exp_c))
            //this.simulation2B.series[2].data.push((this.stats[i]*bet_amount))

            //current_bet = exp_c
            current_bet = current_bet*1.51
            current_bet = (2.5*current_bet-current_bet*2)*2

            if (i < 5) {
             // alert(current_bet*1.51 + ' ' + current_bet2)
            }
            
          //  current_bet = 
          }

/*

          //alert(chased.starting_bet)
          this.simulation2 = {
            series: [{name: 'exp', data: []}],
            labels: [],
          }
          exp_c = 0;
          current_bet = chased.starting_bet
          for( i=0;i < data.length;i++) {
            order = data[i]
           
           price = (10000/Number(order.price));
            bet_amount = current_bet*(3/order.price)

            var bet_amount_proc = (0.2/(((bet_amount*price)-(exp_c))/(i+1)))*3
           // alert(bet_amount_proc)
       
            bet_amount = bet_amount-(bet_amount/3)*bet_amount_proc

            exp_c += Number(bet_amount)
         //   var profit = exp_c-bet_amount*price
            this.simulation2.series[0].data.push(bet_amount*price-exp_c)
            this.simulation2.labels.push(Number(i)+1)

            current_bet = exp_c;
            
          }*/
        })
        .catch((error) => {
          console.log(error);
        });
        
        })
        .catch((error) => {
          console.log(error);
        });

    },


    start_datetime_diff(days) {
        if(days == 0) {
          return "Today";
        }
        if(days == 1) {
          return "Tomorrow";
        }       
        if(days > 1) {
          return days + " days";
        }
    },
    findLeague(league_id) {
      return this.leagues.find((l) => l.id == league_id).name;
    },

    initialize() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/chased/pending";
      this.loadingOverlay = true;
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          this.chased = response.data;
          this.chasedExposure =  {
            series: [{name: 'exp', data: []},{name: 'b', data:[]}],
            labels: [],
          }
          var ch_exp = 0

          var ch_gm = 5
          var ch_c =0;
          var ids = ""
          for(var c=0;c < ch_gm;c++) {
            for(var i=0;i < this.chased.length; i++) {
              if (this.chased[i].matches-1 == c) {
                ch_exp += Number(this.chased[i].amount_spent)
                ch_c++;
              }
              if (ch_gm < this.chased[i].matches)
                ch_gm = this.chased[i].matches
            }
            this.chasedExposure.series[0].data.push(ch_exp || 0)
            this.chasedExposure.labels.push((c+1) + '-' + ch_c)
            ch_exp = 0
            ch_c = 0
          }

          this.loadingOverlay = false
          console.log(ids)
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });



    },
  },
};
</script>