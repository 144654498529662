<template>
<div>
  <h2 style="background-color:orange;padding:0px 5px;text-align:center;margin-bottom:3px;">Bet Insurance</h2>
  <p>
    Ubezpiecz swój mecz od przegranej.<br><br/>
    Jeżeli Twój zakład u bukmachera przegra wypłacimy Ci pieniądze!
  </p>
  <p>
    Jaki zakład postawiłeś/-aś?
  </p>
  <v-form v-model="valid">
    <v-container>
      <v-layout>
  <v-row class="pa-1">
    <v-flex xs4 md4 class="ml-2">
      <v-text-field
      v-model="amount"
      label="Kwota zakładu"
      placeholder="Kwota zakłądu u bukmachera"
      outline
  ></v-text-field>
    </v-flex>
    <v-flex xs3 md3 class="ml-2">
      <v-text-field
      v-model="odds"
      label="Kurs zakładu"
      placeholder="Wpisz kurs"
      outline
  ></v-text-field>
    </v-flex>
<v-flex xs12 md3 class="ml-2">
    <v-text-field
    v-model="searchText"
    label="Wyszukaj mecz"
    placeholder="Wpisz nazwę meczu"
    outline
    @input="search"
></v-text-field>
  </v-flex>
  <v-progress-linear v-if="loading" :indeterminate="true"></v-progress-linear>
  <span v-if="!loading">Propozycje wypłat dla rynków:</span>
  <v-flex xs12 md6 class="pa-2 ml-3 mt-6" v-for="item in markets" :key="item" style="border-top:2px solid #eee">
    <h4>{{ item.eventName }}</h4>
    {{ translateMarket(item.marketType).toUpperCase() }}
    <v-layout>
      <v-flex xs4 md4 class="pa-2 ml-3 text-xs-center" style="border:2px solid #ddd;border-radius:8px;"  v-for="(r, index) in item.runners" :key="r" >
        <strong>{{ translateMarketOption(item.marketType, index) }}</strong><br/><span v-if="r.price > 0">{{ calcPayout(r.price) }}zł</span>
      </v-flex>
    </v-layout>
</v-flex>

</v-row>
</v-layout>
</v-container>
</v-form>

<v-dialog v-model="dialog" persistent max-width="600px">

  <v-card>
    <v-card-title>
      <span class="headline">Calculator</span>
    </v-card-title>
    <v-card-text>

    </v-card-text>
    <v-card-actions>

      <v-flex md2>  
           <v-spacer></v-spacer>
        <v-btn text @click="loadAcca()">Find</v-btn>
        <v-btn text @click="accadialog = false">Close</v-btn>
      </v-flex>
    </v-card-actions>
  </v-card>
</v-dialog>
 
</div>
</template>
<script>
import axios from "axios";
//import annotation from "chartjs-plugin-annotation"

export default {

  data: () => ({
    dialog: false,
    menu: false,
    date: '',
    markets: [],
    loading: false,
    loadingOverlay: false,
    currRates: [
      { mid: 0 }
    ],
    amount: 20,
    odds: 2.5,
    margin: 0.75
  }),
  mounted() {

  },
  created() {
    this.getcCurrencyPrice();
  },
  computed: {

  },
  methods: {
    translateMarket(type) {
      var name = "none";
      switch(type) 
      {
        case "MATCH_ODDS":
          name = "Wynik meczu"
          break;
        case "DOUBLE_CHANCE":
          name = "Podwójna szansa"
          break;
        case "OVER_UNDER_05":
          name = "Powyżej/poniżej 0.5 gola"
          break;
        case "OVER_UNDER_15":
          name = "Powyżej/poniżej 1.5 gola"
          break;
        case "BOTH_TEAMS_TO_SCORE":
          name = "Obie drużyny strzelą?"
          break;
      }
      return name
    },

    translateMarketOption(type, index) {
      var map  = ['1','2','3']
      switch(type) 
      {
        case "MATCH_ODDS":
          map = [ '1' , 'X', '2' ]
          break;
        case "DOUBLE_CHANCE":
          map = [ '1X', '12', 'X2']
          break;
        case "OVER_UNDER_05":
          map = [ 'tak', 'nie' ]
          break;
        case "OVER_UNDER_15":
          map = [ 'tak', 'nie' ]
          break;
        case "BOTH_TEAMS_TO_SCORE":
          map = [ 'tak', 'nie' ]
          break;
      }
      return map[index]
    },


    calcPayout(price) {
      var payout = ((parseFloat(this.odds)/parseFloat(price)) * parseFloat(this.amount)) * this.margin
      return Number(payout).toFixed(2) 
    },

    getcCurrencyPrice() {
      var url = "https://api.nbp.pl/api/exchangerates/rates/a/gbp/last/1/?format=json"
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          this.currRates = response.data.rates;
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingOverlay = false;
        });
    },

    calculateBetting(bet, odds, lay_odds) {
      var ratio = odds / lay_odds
      var avg_ratio = ratio // + ((1-ratio)/2)
      var calc = { 
        odds: Number(odds).toFixed(2),
        lay_odds: Number(lay_odds).toFixed(2),
        bet: bet,
        bookie_income: Number(bet * odds,2).toFixed(2), 
        exchange_income: Number(bet * lay_odds).toFixed(2), 
        lay_amount: Number(bet * avg_ratio).toFixed(2), 
        liability: Number((bet * lay_odds) - bet).toFixed(2),
        ratio: Number(odds / lay_odds).toFixed(2),
        profit: 0,
      }
      calc.profit = Number(calc.bookie_income - calc.exchange_income).toFixed(2)
      return calc
    },

    search() {
      var url = "https://a.bet.lukaszk.usermd.net/v1/search/" + this.searchText
      this.loading = true
      this.markets = []
      axios({
        url: url,
        method: "get"
      })
        .then((response) => {
          this.markets = response.data
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });


    },

  },
};
</script>
<style scoped>
.v-data-footer-hide .v-data-footer {
  visibility: hidden !important;
  display: none !important;
}
</style>